@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import 'react-redux-toastr/src/styles/index';

body {
    margin: 0;
    font-family: 'Source Sans Pro', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto','Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(241, 241, 241, 0.3);
}

.form-group {
    margin: 10px 0;
}

.btn{
    &.bg-primary {
        color: #fff;
    }
}

.alert {
    text-align: center;
}

.desc {
    font-style: italic;
    font-size: 0.8em;
}

.date {
    font-style: italic;
    font-size: 0.8em;
}

.badge-lg {
    font-size: 1.1em;
}

.bg-dark {
    background-color: #f1f1f1 !important;
}

.cb {
    font-size: 0.8em;
    font-style: italic;
    font-weight: bold;
}

.loader {
    min-height: calc(100vh - 45px);
    padding-top: calc(50vh - 45px);
}

.app-bottom-bar {
    li.nav-item {
        list-style: none;
        min-width: 20%;
        text-align: center;
    }
}

.login-form {
    margin-top: calc(50vh - 220px);
}

.btn-setup {
    color: #fff !important;
}

.page-party-list {
    .accordion-button.active {
        color: #fff !important;

        &:after {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
        }
    }
}

.page-invoice-list {
    .card {
        padding: 1.25rem 0.25rem;
        text-align: center;
        color: #fff;

        h4 {
            font-weight: bolder !important;
        }
    }

    .badge-small {
        font-size: 0.6rem;
        margin-top: -10px;
    }

    .badge-notice {
        margin-top: -5px;
        margin-left: 5px;
        position: absolute;
    }

    .date-info {
        font-size: 0.8rem;
    }

    .accordion-button.active {
        color: #fff !important;

        &:after {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
        }
    }
}

.back-to-home {
    position: fixed;
    bottom: 60px;
    left: 20px;
    width: 40px;
    border-radius: 50px;
    text-align: center;
}

.page-header {
    color: #000;
    padding: 1rem;
    padding-bottom: 100px;
    border-radius: 10px;

    h1,
    h2,
    h3 {
        font-weight: bolder;
    }
}

.page-items {
    margin: -50px 1rem 0;
}

.card-item {
    margin: 5px 0;
    border-width: 1px !important;
    border-radius: 6px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -webkit-box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
    box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);

    h1,
    h2,
    h3 {
        font-weight: bolder;
        margin-bottom: 0;
    }
}

.version-number {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0.9rem;
}

.page-vouchers {
    .nav-item {
        margin-bottom: 20px;

        .nav-link {
            color: #000;
            cursor: pointer;

            &:hover {
                color: #000;
            }

            &.active {
                border-bottom: 2px solid #000;
            }
        }
    }

    .accordion-voucher-header {
        position: relative;
        width: 100%;

        .badge {
            float: right;
            margin-right: 10px;
            font-size: 1.1em;
        }
    }
}

.page-dashboard {
    a {
        text-decoration: none;
    }

    .row {
        margin-top: 10px;
    }

    .card {
        min-height: 100px;
        color: #000;

        .card-body{
            padding: 0.5rem;
            text-align: center;

            .card-title {
                text-decoration: none !important;
            }
        }

        .cta {
            position: absolute;
            bottom: 15px;
            left: 15px;
            min-width: 150px;
        }
    }

    .balance.card {
        margin: 10px 0;

        #rzp_payment_form {
            position: absolute;
            bottom: 15px;
            left: 15px;
            min-width: 150px;
        }

        .card-background {
            position: absolute;
            background: no-repeat;
            background-image: url(../assets/balance.png);
            background-position: right center;
            background-origin: content-box;
            background-size: contain;
            top: 0px;
            right: 10px;
            bottom: 0px;
            left: 0px;
            opacity: 0.25;
        }
    }

    .banking.card {
        margin: 10px 0;

        .card-body {
            z-index: 100;
        }

        .card-background {
            position: absolute;
            background: no-repeat;
            background-image: url(../assets/bank.png);
            background-position: right center;
            background-origin: content-box;
            background-size: contain;
            top: 0px;
            right: 10px;
            bottom: 0px;
            left: 0px;
            opacity: 0.25;
        }
    }

    .contact.card {
        margin: 10px 0;

        .card-body {
            z-index: 100;
        }

        .card-background {
            position: absolute;
            background: no-repeat;
            background-image: url(../assets/sales-person.png);
            background-position: right center;
            background-origin: content-box;
            background-size: contain;
            top: 0px;
            right: 10px;
            bottom: 0px;
            left: 0px;
            opacity: 0.25;
        }
    }

}

.page-business {
    .nav-item {
        margin-bottom: 20px;

        .nav-link {
            color: #000;
            cursor: pointer;

            &:hover {
                color: #000;
            }

            &.active {
                border-bottom: 2px solid #000;
            }
        }
    }
}

.page-payslip {
    .card {
        margin-bottom: 1rem;
        padding: 0;

        &.earnings,
        &.deductions,
        &.net {
            .list-group-item {
                div {
                    text-align: right;
                    float: right;
                }
            }
        }

        &.net {
            .list-group-item {
                div {
                    font-size: 1.2rem;
                    font-weight: bolder;
                }
            }
        }
    }

    .btn{
        color: white;
        font-weight: bold;
    }
}

.page-attendance {
    .accordion {
        .accordion-header {
            .badge {
                position: absolute;
                right: 50px;

                &.bg-primary {
                    right: 0;
                }
            }
        }

        .list-group-item {
            .alert {
                padding: 0.25rem;
            }
        }
    }
}